#Footer {    width: 100%;  background-color: rgb(39,39,39);  color: #adadad;  text-align: left;  font-size:12px; }
#footer_div   {margin-left: 5px;}



/* For desktop: */
@media only screen and (min-width: 1024px) {

    #Footer {   position: fixed; left: 0; bottom: 0; }
        
    #footer_div    { max-width: 1024px; margin:0px auto; }

    
}    