
/*  mobile first !! */



body {
  /*font: normal 10px Georgia, "Times New Roman", serif;, erdana, Arial, sans-serif;*/
  font-family: Open Sans,"Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
  font-size: 16px;	font-weight: 400;	line-height: 1.7em;	color: #2a2b2c; margin:0px;

}


.App {
background-color: white;
box-sizing: border-box;
}




/* For desktop: */
@media only screen and (min-width: 1024px) {

/*  body {  max-width: 1024px;        margin: 0 auto;    }*/
  


}