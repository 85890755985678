#city_list {background-color: #f7f7f7; padding-bottom: 30px; min-height:99vh;}


    #main_banner_div {display: block; height:250px; position:relative; text-align:center; }
        #main_banner_img  {  width:100%; height:250px; object-fit: cover; filter:brightness(80%) }   
        #main_banner_title_div { position:absolute; top:140px; width:100%;  z-index:3; }
            #main_banner_title { color:white; width:100%;  margin:0 auto;  text-align: center;       height: 40px;    line-height: 40px; font-size:30px; }



    #country_ul_div { margin-top:20px;}

    .country_head {    margin: 15px 0px 0px 5px;  padding: 5px; height:30px;}
        .country_flag {width: 35px; height: 25px; vertical-align: middle;}
        .country_name { font-size: 20px; font-weight: 700; color: rgb(73, 73, 73); line-height: 20px; vertical-align: middle; padding-left: 6px;}

        .city_ul { margin: 10px 0px 30px 10px;  padding:5px; list-style: none; }
            .city_li_div { padding:5px; cursor: pointer; position: relative; width:120px; height:120px; display:inline-block; }
                .city_item_img { width: 100%; height: 100%; border-radius: 50%; }
                .city_ltem_title { color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background: #3737375c; font-size: 15pt; line-height: 40px; min-height:40px; text-align: center; width:70%; }
                .city_item_cc { width: 25%; height: 18%; position: absolute; top: 5px; left: 0; border: 0px solid #999;  }



/* For desktop: */
@media only screen and (min-width: 1024px) {

     
    #country_ul   { max-width: 1024px; margin:20px auto; }
    #city_ul_div    { max-width: 1024px; margin:20px auto; }

    
}    