
#city_page_div    {    width: 100%;    height: calc(100vh - 65px);     position:relative; border-top: 1px solid #777; }

    #city_page_top_div { height:35px; line-height: 30px; background-color: #e7e7e7; color: #222; display: flex; align-items: center;}
        #top_div_list_icon { height:25px;   margin-left: 10px;}

    .iframe_map {width: 100%;  height: calc(100vh - 140px); border: none; } /* width: 100%;  height: 100%; border: none; position: absolute; top: 0;  left: 0;  */


    #city_page_bottom_div { height:35px; line-height: 30px; background-color: black; color: white; font-size:13px;  text-align: center;  overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}




/* For desktop: */
@media only screen and (min-width: 1024px) {
    .iframe_map { height: calc(100vh - 170px); }
}