
#filtering_ordering_div { margin:5px 0px 10px 0px; padding:5px; background-color: #2346ff; }


#city_page_div    {    width: 100%;   /* height: calc(100vh - 65px); */    position:relative; }

    .iframe_map {width: 100%;  height: 600px;  border: none; } /* width: 100%;  height: 100%; border: none; position: absolute; top: 0;  left: 0;  */

    .spot_li { height:110px; margin:5px; position:relative; cursor:pointer;}
        .spot_li_img { width:96px; height:96px; float:left; margin:5px; border-radius: 5px; }
        .spot_li_text { margin-left:115px; height:110px; margin-right: 5px;}
            .spot_li_title { height:35px; line-height: 40px; font-size: 18px; font-weight: bold; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
            .spot_li_subtype { height:30px; line-height: 30px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}

    .TabList   { border-bottom: 1px solid #ccc;}




    .DetailPagePopup { position: fixed; top: 50%; left: 50%; width: 100%; height: 100%; transform: translate(-50%, -50%);
            padding: 0; z-index: 100; background-color: rgb(0 0 0 / 70%); box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);    }

        .dp_close_btn {  font-size: 90;  color:red;  }

        .dp_inner_box {
            background-color: white;            position: relative;
            /*width: 90%;  height: 90%; left: 5%; top: 0%;*/
            width: 100%;  height: 95%; left: 0%; top: 0%;
            padding:10px; box-sizing: border-box; border-radius: 10px; 
        }

            .dp_title { font-weight: bold; font-size: 24px; height:40px; line-height: 40px; }
            .dp_subtype { font-size: 16px; height:40px; line-height: 30px; border-bottom: 1px solid #ccc; margin-bottom: 5px; }
            .dp_body { overflow: auto; position:relative; top:0; height:90%; }
            .dp_img { width: 100%; height: 300px; margin-top:10px;}
            .dp_data_box { margin-bottom: 25px;}
                .dp_data_key { font-weight: 700; font-size: 18px; height: 45px; line-height: 45px;}
                .dp_data_value {  padding-left:10px;}
                    .dp_data_subkey { width:100px; float:left;}
                    .dp_data_subvalue { margin-left:110px;}

                .dp_data_map_div { height:270px;}

                .dp_googlemap_link { height:20px; line-height: 20px; text-align: right;}

                .dp_data_linkbox_a  { text-decoration: none; }
                    .dp_data_linkbox  { height:50px; line-height: 50px; /* border:1px solid #ccc; */ border-radius: 4px; margin-top:10px; margin-bottom: 10px; 
                        cursor:pointer; text-align: center; color:black; background-color: #ffcc33a1; }


    
/*

.spot_list_table {display:table; border-collapse: collapse; margin:10px;}        
    .Hotel_list_row {display:table-row; border-bottom: 1px solid #eee; margin-bottom: 20px;}        
        .HL_tier_number_div { display:table-cell; width:70px; height:40px; text-align: center; vertical-align: top; font-weight: 600; }
        .HL_list_div    {display:flex; flex-wrap:wrap; justify-content: space-between; }
    .HL_border {display:table-row; height: 30px; padding:20px; }

*/

/*
.spot_list_table {}        
    .Hotel_list_row {}        
*/
        .HL_tier_number_div { font-weight: 600;  height:45px; line-height: 45px; padding-left: 10px; padding-right:10px; font-size: 20px; background:whitesmoke; }
            .tier_text { color:#515151;  }
        /*
        .HL_tier_number_div.S { background: linear-gradient(to right, rgb(255, 127, 127)  0%, #ffffff 30%);  }
        .HL_tier_number_div.A { background: linear-gradient(to right, rgb(255, 191, 127)  0%, #ffffff 30%); }
        .HL_tier_number_div.B { background: linear-gradient(to right, rgb(255, 217, 86)  0%, #ffffff 30%);  }
        .HL_tier_number_div.C { background: linear-gradient(to right, rgb(255, 255, 127)  0%, #ffffff 30%); }
        .HL_tier_number_div.D { background: linear-gradient(to right, rgb(191, 255, 127)  0%, #ffffff 30%); }
        */
        .HL_list_div    { padding:10px;  display: flex;  flex-wrap: wrap;  justify-content: space-between;}
    .HL_border {height: 30px; }


    .spot_item { float:left; width:100%; padding-bottom: 10px; padding-top: 10px; border-bottom:1px solid #ddd;}
        .spot_item>a {text-decoration: none; color:#2a2b2c;}
        .spot_item_img { width:86px; height:86px; border-radius: 3px; float:left; }
        .spot_item_info { margin-left:100px; }
        .spot_item_title { height:30px; font-size:16px; line-height: 30px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-weight: 700; }
            .spot_index.hotel {color:blue;}
        .spot_item_price { height:30px; line-height: 30px; width:100%; }
            /* .spot_item_price_span { background-color: rgb(80,0,160); cursor:pointer; padding:7px 10px 7px 10px; color:white;  border-radius: 5px; } */
            .spot_item_price_span { cursor: pointer; /* padding: 7px 10px 7px 10px; */ color: #fc5230; border-radius: 5px; text-decoration: underline; font-size: 19px; font-weight: bold; 
                                    text-underline-offset: 5px; float:left; } 
        .spot_item_price_avg {height:30px; float:left; font-size: 14px;}
        .spot_item_price_avg.expensive { color:#ff1111;}
        .spot_item_price_avg.moderate { color:#5f5f5f;}
        .spot_item_price_avg.cheap { color:#1f1fff;}

#div_empty_result { margin: 200px auto; width: 250px;}
    #img_empty_result { width: 120px; margin-left:65px; margin-bottom: 20px;}
    #msg_empty_result { color: #4b4b4b; text-align: center; }


.margin_div {height:20px;}            


/* For desktop: */
@media only screen and (min-width: 1024px) {

    .dp_close_div { max-width: 700px; margin:0 auto;}
    .dp_inner_box { max-width: 700px; margin:0 auto;}

        
    #spot_list_div    {  max-width: 1024px; margin:0px auto; }


    
    .spot_list_table {display:table; border-collapse: collapse; margin:10px; width:1004px;}        
        .Hotel_list_row {display:table-row; border-bottom: 1px solid #eee; margin-bottom: 20px;}        

        .HL_list_div    {display:flex; flex-wrap:wrap; justify-content: left; }
/*
            .HL_tier_number_div { display:table-cell; width:70px; height:40px; text-align: center; vertical-align: middle; font-weight: 600; }
            .HL_list_div    {display:flex; flex-wrap:wrap; justify-content: left; }

            .HL_tier_number_div.S { background:unset; border-left:9px solid  rgba(255, 127, 127, 0.6); }
            .HL_tier_number_div.A { background:unset; border-left:9px solid  rgba(255, 191, 127, 0.6);}
            .HL_tier_number_div.B { background:unset; border-left:9px solid  rgba(255, 217, 86, 0.6);  }
            .HL_tier_number_div.C { background:unset; border-left:9px solid  rgba(255, 255, 127, 0.6); }
            .HL_tier_number_div.D { background:unset; border-left:9px solid  rgba(191, 255, 127, 0.6);  }
*/        
                .spot_item { margin:18px; }

        .HL_border {display:table-row; height: 30px; padding:20px; }

    
}    