#header {
    height: 65px;
    z-index: 1;
    position: relative;
}
    #header_logo_div    { width:150px; height:60px; float:left;}
        #header_logo_img { height:50px; cursor:pointer; margin-top:8px; margin-left:5px; }
    #header_sub_loc_div { float:left; margin-left:75px; }
        #header_subtitle   {    float: left;     line-height: 28px;  padding: 0px 10px 0px 10px; font-size: 14px; height: 28px; 
                            border: 1px solid #616161; border-radius: 3px; color: #555;
                            margin-top: 20px;  font-weight: bold; cursor:pointer; }



/* For desktop: */
@media only screen and (min-width: 1024px) {

    #header {  max-width: 1024px;        margin: 0 auto;    }
      
    
    
}    